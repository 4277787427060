<template>
<div class="manager-view" >
    <h3>
        Admission Procedure for Existing Park Companies
    </h3>
    <br/>
    <p>Please follow the below steps to submit an application</p>
    
    <div>
      <h4>1. Create your login account</h4>
      <ul style="padding-left: 14px;" class="ui-nostyle">
          <li>Each applicant should only create one login account</li>
      </ul>
        <h4>2. Read the InnoCell Admission Guide (<a href="https://innocell.hkstp.org/files/PartAExistingParkCompanyGuide.pdf" target="_blank">download here</a>)</h4>
        <h4 style="padding-left: 14px;">Login and complete the online application</h4>
        <ul class="ui-nostyle" style="padding-left: 14px;">
          <li>Below are the information you need to prepare now before fill in the application form</li>
          <li>a. Check your Company's lease period at HKSTP</li>
          <li>b. Signed Declaration Form (<a href="https://innocell.hkstp.org/files/Section5DeclarationforPartA.pdf" target="_blank">download here</a>)</li>
          
          
         
      </ul>
      <h4>3. Documents to be verified by HKSTP</h4>
      <ul style="padding-left: 14px;" class="ui-nostyle">
          <li>We will contact you to submit the supporting documents if your application is successful</li>
      </ul>
    </div>
    <p class="footer-btn" style="text-align:center;"><a class="btn btn-primary" @click="registerCloseView">OK</a></p>
</div>
</template>
<script>
import global from '../global'
import jq from 'jquery'
export default {
  methods: {
     registerCloseView(){
          var make = document.getElementById("_makeHtml");
          console.log(make)
          make.remove();
          location.href="#/register"
      }
  }
}
</script>