<template>
<div id="Home" class="Home">
    <a-row>
        <a-col class="nav">
            <Nav></Nav>
            <Chatbot></Chatbot>
        </a-col>
        
        <a-col class="header_animation" v-if="1 == isVideo">
            <div >
                <video autoplay="autoplay" muted="muted" loop="loop" width="100%" height="100%">
                  <source :src="videoUrl" type="video/mp4">
                  <source :src="videoUrl" type="video/ogg">
                您的浏览器不支持Video标签。
                </video>
                <div class="video-screen"></div>
            </div>
        </a-col>
        <a-col id="innocell" class="header_title" >
            <p >
                
                    A SMART <i></i>
                    <span style="font-family: 'THRONE VECTOR';position:relative;display: inline-block;">
                      CO-CREATION
                      <img :src="gifBlueUnderline" alt="" class="blurundeerline">
                    </span> <i></i>
                   <span style="position:relative;display: inline-block;">COMMUNITY
                    <img id="mobile-gifBlueExclaimation" :src="gifBlueExclaimation" alt="" >
                   </span><u></u>
                    FOR <i></i>LIKEMINDED PEOPLE 
                    
            </p>
            
        </a-col>
        <a-col class="header_animation" v-if="2 == isVideo">
           <div>
             <img :src="gifHeaderAnimation" alt="header_animation">
           </div>
        </a-col>
        <a-col class="intro_card mian">
            <div class="intro_card_text">
                <div class="intro-item intro-item01 intro-active">
                    <div class="intro-item-content">
                        InnoCell is a smart living and co-creation space
                        designed for I&T talents to spark collaboration
                        within <a href="https://www.hkstp.org" target="_blank" style="text-decoration:underline">Hong Kong Science Park</a>.
                        <br/>
                        <br/>
                        All of our spaces are designed with a well-considered balance between <span style="font-family: 'THRONE VECTOR';">AESTHETICS</span>
                        and <span style="font-family: 'THRONE VECTOR';">FUNCTIONALITY</span>. Our vision is to
                        develop an I&T co-creation community, where our
                        values and structures align with our residents’
                        needs.
                    </div>
                    <div class="intro_right_btn" ><img :src="gitIntrRigthBtn" @click="introRightBtn('intro-item01')" class="click-btn" data-aid="intro-item02" alt=""></div>
                </div>
                <div class="intro-item intro-item02">
                    <div class="intro-item-content">
                        At InnoCell, we have created a new way of living,
                        focused on a genuine sense of community, where
                        the use of shared spaces and smart living
                        technologies helps to build greater personal
                        <span style="font-family: 'THRONE VECTOR';">INTERACTION</span> and <span style="font-family: 'THRONE VECTOR';">COLLABORATION</span>
                        — resulting in a network of likeminded talents who
                        inspire one another and have a positive impact.
                    </div>
                    <div class="intro_right_btn" ><img :src="gitIntrRigthBtn" @click="introRightBtn('intro-item02')"  class="click-btn" data-aid="intro-item01" alt=""></div>
                </div>
            </div>
            <img :src="gitIntroCard" class="intro_card_img" width="100%" alt="">
        </a-col>
        <a-col class="mian">
            <CoCarousel></CoCarousel>
        </a-col>
        <a-col id="room" class="mian">
            <SmartLiving></SmartLiving>
        </a-col>
        <a-col class="mian">
            <a-col class="smart_end_title smart_end_subtitle ">
                <p >
                    GREAT<span style="position: relative;">
                    　SPACES　
                     <img :src="green_squiggle_animation" style="width:120%;position: absolute;left:-10px;top:20px;z-index:-1;"/>
                    </span>FOR GREAT
                    <span style="position: relative;">　MINDS　
                    <img :src="green_squiggle_animation2" style="width:120%;position: absolute;left:-5px;top:20px;z-index:-1;"/>
                    </span>
                    <br />
                    TO BUILD GREAT <span style="position: relative;">
                    IDEAS
                    <img :src="yellowzigzag" style="width:120%;position: absolute;left:-5px;top:25px;z-index:-1;"/>
                    </span>
                   
                </p>
            </a-col>
        </a-col>
        <a-col class="spaces mian" id="spaces" style="position: relative;">
            <img :src="pngmobileSpaces" width="100%" class="pcMoblieBg" alt="">
            <a-row class="spaces-text text-left">   
                <div class="spaceStep1">
                    <a-col>
                        <span class="space_title" style="position: relative;">
                        SPACES
                        <img class="spaces-title-box" :src="gifYellowBox" alt="" >
                        </span>
                    </a-col>
                    <a-col>
                        <div class="mtop25">
                            <p>
                                <span class="pc-space-subtitle">Looking for a place to relax or socialise after work?  We got you! </span> 
                                InnoCell provides you with spaces designed to ignite your imagination and creativity.  In addition to your own fully-furnished private room, we offer a well-rounded living experience with approximately 30,000-sqft of tailored communal amenities to work, live, play and gather.
                            </p>
                        </div>

                    </a-col>
                </div>
                <div class="spaceStep2">
                    <div id="spaceStep-2">
                        <a-col>
                            <div class="mtop20">
                            It is our mission is to provide our residents with a healthy lifestyle and to encourage co-creation within the community.  We have created a considered balance between personal spaces and interactive areas to give you the best of both worlds.  Our well thought-out spaces include clubhouse zones for play, as well as designated knowledge exchange areas for work.
                            </div>
                        </a-col>
                        <a-col>
                            <div class="mtop20">
                            We utilise a modern placemaking approach to living, to help develop communities that make an impact and create value structures that align with our residents’ needs.  From the moment you arrive at InnoCell, you get access to our dynamic ambiance, multifunctional facilities, co-creation community and seamless smart services.
                            </div>
                    </a-col>
                    <div class="space_bottom_img"><img :src="spaceBottomImg" /></div>
                    </div>
                </div>
                <div class="spaceStep3">
                    <a-col :span="24" class="mtop25">
                        <a-row>
                            <a-col :span="12">
                                <h4><i>WORK Zone</i></h4>
                                <p><font>•</font><span>Meeting Rooms</span></p>
                                <p><font>•</font><span>Hot Desks</span></p>
                                <p><font>•</font><span>Multi-Purpose Function Rooms</span></p>
                                <p><font>•</font><span>Stepped-Seating Lecture Hall</span></p>
                            </a-col>
                            <a-col :span="12">
                                <h4><i>PLAY Zone</i></h4>
                                <p><font>•</font><span>Interactive Game Zones</span></p>
                                <p><font>•</font><span>Music Rooms</span></p>
                                <p><font>•</font><span>Party Room</span></p>
                                <p><font>•</font><span>Reading Corner</span></p>
                                <p><font>•</font><span>Lounge & BBQ Area</span></p>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24" class="space-footer-text2 mtop25">
                        <a-row>
                            <a-col :span="12" class="space_bottom_text">
                                <h4><i>RELAX Zone</i></h4>
                                <p><font>•</font><span>Recreational Greenery</span></p>
                                <p><font>•</font><span>Green Indoor & Outdoor Social Space</span></p>
                                <p><font>•</font><span>Semi-Outdoor Cross-Fit Area</span></p>
                                <p><font>•</font><span>Gym & Fitness Centre</span></p>
                            </a-col>
                            <a-col :span="12">
                                <h4><i>ESSENTIALS</i></h4>
                                <p><font>•</font><span>Smart Vending Machines</span></p>
                                <p><font>•</font><span>Micro-Market</span></p>
                                <p><font>•</font><span>E-Lockers</span></p>
                                <p><font>•</font><span>Communal Kitchens</span></p>
                                <p><font>•</font><span>Smart Self-Service Laundry and Dry-Cleaning Service</span></p>
                            </a-col>
                        </a-row>
                    </a-col>
                    
                    
                </div>
            </a-row>
            <div class="space_step_btn1"  data-aid="2"><b>{{spaceBtnText}} ＞ </b></div>
            <div class="pcSpaceBg">
                <img :src="pngSpaces" width="100%"  alt="">
            
            </div>
            
        </a-col>
        <a-col class="mian">
            <div class="smart_end_title space_end_title ">
                <span >COMMUNITIES INSPIRE US <br/>— THEY ARE VALUABLE.
                    <img :src="blueArrow" style="position: absolute;width:100px;top:-80px;left:50%;margin-left: -50px;"/>
                </span>
            </div>
        </a-col>
        <a-col class="community mian" id="community">
            <a-col><img :src="pngCommunityTop" width="100%" alt="" class="pngCommunitytop"></a-col>
            <a-row class="community-text">
                <a-col class="community-title">COMMUNITY<img :src="gifBlueSmile" class="community-smale" alt=""></a-col>
                <a-col>
                     <span class="community_subtitle_work">All work, no play?  Not at InnoCell! </span>
                     Knowledge may be power but, hey, having fun is pretty powerful, too.  That’s why, in addition to knowledge-exchange events to help inspire our tenants and promote co-creation, InnoCell also has a calendar of exciting social happenings to help you relax and unwind.  Stay tuned for event news updates from InnoCell.<br/><br/>
 We may be selective, but we’re not snobby.  InnoCell’s community is curated and hand-picked by us to ensure that all of our residents’ values are aligned with a common goal — to inspire progress, create impact and instigate change.
                </a-col>
            </a-row>
            <img :src="pngCommunity" width="100%" alt="" class="pngCommunity">
        </a-col>
        <a-col class="together text-right mian" >
            <a-row>
                <a-col :span="14"></a-col>
                <a-col :span="10" style="" class="together-title">
                    <div class="together-text text-center">
                        TOGETHER, WE GO FURTHER.<br />
                        WE IS GREATER THAN ME
                    </div>
                    <img :src="gitCircled" width="100%" alt="" class="together_title_gif">
                </a-col>
            </a-row>
        </a-col>
         <a-col class="mian">
            <Together></Together>
        </a-col>
        <a-col class="touch text-left" style="" id="touch">
            <a-row class="footer-touch-m">
                <div class="mian">
                    <a-col class="touch-title ">
                        GET IN <span></span>TOUCH<br />
                    </a-col>
                </div>
                <a-col class="touch-content">
                   <div class="mian" style="padding-left:0;">
                        <a-col :span="18" class="touch-content-text mian">
                     The first round of InnoCell admissions for existing park company tenants will tentatively begin Early-2021.<i class="br mtop20"></i>
                     For any inquiries, please feel free to email us at <a style="text-decoration: underline;color:#000;" href="mailto:innocell.general@hkstp.org">innocell.general@hkstp.org</a>.<br />
                   </a-col>
                   <div style="clear: both;"></div>
                   </div>
                </a-col>
                <a-col class="touch-footer mian">
                    1 Chong San Road,
                    Tai Po, New Territories,<br />
                    Hong Kong S.A.R.<br />
                </a-col>
                <div class="mian m-touch-img"> 
                    <a-col class="touch-img">
                        <img :src="pngMan" alt="">
                    </a-col>
                </div>
            </a-row>
        </a-col>
    </a-row>
    <a-back-top />
    <Dialog></Dialog>
</div>

</template>

<script>
import Nav from '@/components/Nav'
import Chatbot from '@/components/Chatbot'
import CoCarousel from '@/components/CoCarousel'
import SmartLiving from '@/components/SmartLiving'
import Together from '@/components/Together'
import Dialog from '@/components/Dialog'
import $ from 'jquery'
import global from '../global'
export default {
    name: 'Home',
    components: {
        Nav,
        CoCarousel,
        SmartLiving,
        Together,
        Chatbot,
        Dialog
    },
    data() {
        return {
            gifHeaderAnimation: require('../assets/img/gif/header_animation.gif'),
            gifBlueUnderline: require('../assets/img/gif/blue_underline_gif.gif'),
            gifBlueExclaimation: require('../assets/img/gif/blue_exclaimation_gif.gif'),
            gifBlueSmile: require('../assets/img/gif/blue_smile_animation.gif'),
            gifYellowBox: require('../assets/img/gif/yellow_box_gif.gif'),
            gitIntroCard: require('../assets/img/gif/intro_card.gif'),
            gitCircled: require('../assets/img/gif/circled.gif'),
            pngSpaces: require('../assets/img/spaces_background.png'),
            pngmobileSpaces:require('../assets/img/spacespcMoblieBg01.png'),
            pngCommunity: require('../assets/img/community_background.png'),
            pngMan: require('../assets/img/man.png'),
            blueArrow:require('../assets/img/gif/blue_arrow_gif.gif'),
            vimeoURL:'https://vimeo.com/468811485/3a7edbd5de',
            green_squiggle_animation:require('../assets/img/gif/yellowline.gif'),
            green_squiggle_animation2:require('../assets/img/gif/yellowline2.gif'),
            yellowzigzag:require('../assets/img/gif/yellowzigzag.gif'),
            videoUrl:require('../assets/img/header_animation.mp4'),
            spaceBottomImg:require('../assets/img/space_bottom_img.png'),
            gitIntrRigthBtn:require('../assets/img/right_black.png'),
            clientWidth:0,
            pngCommunityTop:require('../assets/img/community-top.png'),
            screenWidth:0,
            timer:false,
            screenWidths:0,
            isLoading:null,
            isVideo:2,
            SpaceBoxHeight:0,
            aid:1,
            spaceBtnText:'Read More',
            isClick:false,
        }
    },
    mounted() {
        
        this.$cookies.set('language','en')
        var that = this
        that.clientWidth = global.getClientWidth();
        this.$nextTick(function(){
            let h = $(".intro_card").find('.intro-item').height();
            $(".intro_card_text").css({height:(h+120)+"px"})
            var items = document.getElementsByClassName("intro-item");
            if(items.length > 0){
                var i = 0;
                Array.prototype.forEach.call(items, function (element) {
                    if(i == 0){
                        element.style.left= "0px";
                        element.classList.add('intro-active');
                    }else{
                        element.style.left = that.clientWidth+"px";
                    }
                    i++;
                })
                
            }

            // 点击
            $("body").on("click",".space_step_btn1",function(){
                that.aid++;
                if(that.aid > 3){
                    that.aid = 1;
                }
                var aid = that.aid;
                if(global.getScreenWidth() <= global.mobileWidth && (aid == 2 || aid == 3)){
                    $("#spaces").find(".spaces-text").css({"height":that.SpaceBoxHeight+"px"}) 
                }else{
                    $("#spaces").find(".spaces-text").css({"height":"auto"})
                }
                
                if(aid == 1){
                    $(".spaceStep3").hide();
                    $(".spaceStep1").show();
                    $("#spaces").removeClass("spacesHover");
                    that.spaceBtnText = "Read More";
                }else if(aid == 3){
                     $(".spaceStep2").hide();
                     $(".spaceStep3").show();
                     that.spaceBtnText = "Spaces";
                }else{
                    $(".spaceStep2").show();
                    $(".spaceStep1").hide();
                    $("#spaces").addClass("spacesHover");
                    that.spaceBtnText = "Click to see the different zones";
                }
            })
        })
        if(that.clientWidth <= 768){
            this.isVideo = 2;
        }else{
            this.isVideo = 1;
        }
         window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
            if(that.screenWidths == undefined){
                return ;
            }
            if(that.screenWidths <= 768){
                that.isVideo = 2;
            }else{
                that.isVideo = 1;
            }
        });
        
       
        window.addEventListener("orientationchange", function() {
            setTimeout(function(){
                if(global.getScreenWidth() <= global.mobileWidth){
                    $(".spaceStep1").show();
                    $(".spaceStep2,.spaceStep3").hide();
                    $("#spaces").removeClass("spacesHover");
                    setTimeout(function(){
                        that.SpaceBoxHeight = $("#spaces").height()-72;
                    },50)
                 }else{
                    $("#spaces").removeClass("spacesHover");
                    $(".spaceStep1,.spaceStep2,.spaceStep3").show();
                    $("#spaces").find(".spaces-text").css({"height":"auto"})
                 }
            },100)
        }, false)

        // 手机点击
        $(document).on('touchstart','.click-btn',function(){
           $(this).css({transform:"rotate(10deg)"})
           var that = this;
           setTimeout(function(){
             $(that).css({transform:"rotate(0deg)"})
           },200)
        })
    },
    methods: {
        introRightBtn(domeId){
            if(this.isClick){
                return ;
            }
            this.isClick = true;
            this.clientWidth = global.getClientWidth();
            $('.intro-item').removeClass("intro-active")
            console.log(domeId)
            if('intro-item02' == domeId){
                $('.intro-item02').css({"left":(this.clientWidth)+"px"})
                $(".intro-item01").animate({"left":"0px"},'normal');
                $(".intro-item01").addClass("intro-active")
            }else{
                $('.intro-item01').css({"left":(this.clientWidth)+"px"})
                $(".intro-item02").animate({"left":"0px"},'normal');
                $(".intro-item02").addClass("intro-active")
            }
           this.isClick = false;
        },
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  this.screenWidths = newVal;
                  
                  let h = $(".intro_card").find('.intro-item').height();
                  $(".intro_card_text").css({height:(h+120)+"px"}) 
                  var _this = this
                  $(".intro-item").css({left:this.clientWidth+"px"})
                  $(".intro-item.intro-active").css({left:"0px"})
                  _this.timer = true;
                  setTimeout(function(){
                     _this.clientWidth = global.getClientWidth();      
                     if(global.getScreenWidth() <= global.mobileWidth){
                        $(".spaceStep1").show();
                        $(".spaceStep2,.spaceStep3").hide();
                        $("#spaces").removeClass("spacesHover");
                        setTimeout(function(){
                            _this.SpaceBoxHeight = $("#spaces").height()-72;
                            console.log(_this.SpaceBoxHeight+"------")
                        },50)
                     }else{
                        $("#spaces").removeClass("spacesHover");
                        $(".spaceStep1,.spaceStep2,.spaceStep3").show();
                     }
                     $("#spaces").find(".spaces-text").css({"height":"auto"})
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },
    
}
</script>

<style lang="less">

</style>
