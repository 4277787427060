import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/zh-CN",
  //   name: "zh-CN",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "../views/cn.vue"),
  // },
  // {
  //   path: "/zh-HK",
  //   name: "zh-HK",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "../views/hk.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("../views/register.vue"),
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("../views/login.vue"),
  // },
  // {
  //   path: "/applying",
  //   name: "Applying",
  //   component: () => import("../views/applying.vue"),
  // },
  // {
  //   path: "/welcome",
  //   name: "Welcome",
  //   component: () => import("../views/welcome.vue"),
  // },
  // {
  //   path: "/findpassword",
  //   name: "Findpassword",
  //   component: () => import("../views/findpassword.vue"),
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () => import("../views/About.vue"),
  // },
  {
    path: "*",
    name: "notfound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  //mode: 'history',
  routes,
});

export default router;
