<template>
<div class="m-together">
<a-row id="Together" >
    <a-col>
        <img :src="pngImg" width="100%" alt="Test" @click="jumpUrl">
       <a-row class="togeter-button">
            <a-col :span="3" @click="up_info"><img :src="pngLeft" class="click-btn" alt=""></a-col>
            <a-col :span="9" @click="up_info" :style="pngLeftTextStyle">{{pngLeftText}}</a-col>
            <a-col :span="9" @click="next_info" :style="pngRightTextStyle">{{pngRightText}}</a-col>
            <a-col :span="3" @click="next_info"><img :src="pngRight" class="click-btn" alt=""></a-col>
        </a-row>

        <div class="togeter-background">

        </div>
        <div class="togeter-text text-left togeter-text-bg">
            <a-col :span="12" @click="next_info" class="mobile-togeter-button"><img :src="pngRightBack" alt="" class="click-btn"></a-col>
            <p v-if="1 === imgIndex">
                <span class="together-title">ABOUT HKSTP</span><br />
                <br />
                Hong Kong Science Park is the city’s largest R&D base. Poised to be Asia’s leading innovation hub, HKSTP is making it happen, building a hive of technology development—strategically located in the Greater Bay Area—to influence the world.  HKSTP is a collective ecosystem of go-getters who dream and achieve — a launchpad for you to connect to the most cutting-edge industries and market opportunities worldwide.  Whether you’re an entrepreneur or corporate stakeholder, at HKSTP, you’ll have all the infrastructure, knowledge, collaboration and drive you need to succeed and excel through innovation and technology.

            </p>
            <p v-else-if="2 === imgIndex">
                <span class="together-title">THE NEIGHBOURHOOD</span><br /><br />
                Situated on the beautiful and serene Tolo Harbour, Hong Kong Science Park offers a host of <a style="text-decoration:underline;color:#000" href="https://www.hkstp.org/our-community/park-amenities/" target="_blank" ><b>additional amenities</b></a> for our community to enjoy.  From a wide range of culinary offerings to local supermarkets, bank branches and health services, you can conveniently find everything you need right here within the Park.  The HKSTP Clubhouse is accessible by Park tenants only, offering our community a space to relax and unwind at our sauna and swimming pool, get a workout in at our fitness centre or enjoy some downtime playing table tennis.  
                <a-row class="toget-icon-boax">
                    <a-col :span="8">
                        <div class="subTime-img-text images001">
                            SUPERMARKET
                        </div>
                    </a-col>
                    <a-col :span="8">
                    <div class="subTime-img-text images004">CLUBHOUSE</div>
                    </a-col>
                    <a-col :span="8">
                    
                    </a-col>

                    </a-row>
                    <a-row >
                        <a-col :span="8" >
                        <div class="subTime-img-text images003">HEALTHCARE</div>
                        </a-col>
                        <a-col :span="8">
                        <div class="subTime-img-text images002">BANKING</div>
                        </a-col>
                        <a-col :span="8">
                        <div class="subTime-img-text images005">RESTAURANTS</div>
                        </a-col>

                    </a-row>
            </p>
            <p v-else>
                <span class="together-title">GETTING HERE</span><br /><br />
                Conveniently located in Hong Kong, Science Park is accessible within 15-minutes by car and 30-minutes by public bus from the Central Business District.  It is also just 30-minutes way from the China border of Shenzhen.  With easily accessible public transportation links, HKSTP is just minutes away from the University and Shatin MTR Stations on the East Rail Line, and is also a stop on many public bus routes.  With a multitude of transportation options on offer, including our private shuttle bus service from the nearby MTR, you can enjoy the best of both worlds; a world-class facility for your work and an international metropolis for you to explore
            </p>
            <img v-if="1 === imgIndex" :src="pngAboutHkstp1" class="togeter-text-background" alt="">
        </div>
         
    </a-col>
</a-row>
    <div class="map_tagether">
        <div class="map_tageher_header_border"></div>
        <img :src="mapTagetherPNG"  @click="jumpUrls"/>
        <div class="mobile-map-text">
            <span class="together-title">GETTING HERE</span><br /><br />
            Conveniently located in Hong Kong, Science Park is accessible within 15-minutes by car and 30-minutes by public bus from the Central Business District.  It is also just 30-minutes way from the China border of Shenzhen.  With easily accessible public transportation links, HKSTP is just minutes away from the University and Shatin MTR Stations on the East Rail Line, and is also a stop on many public bus routes.  With a multitude of transportation options on offer, including our private shuttle bus service from the nearby MTR, you can enjoy the best of both worlds; a world-class facility for your work and an international metropolis for you to explore
        </div>
    </div>
</div>

</template>

<script>
import global from '../global'
export default {
    name: 'Together',
    data() {
        return {
            pngLeftWhite: require('../assets/img/left_white.png'),
            pngRightWhite: require('../assets/img/right_white.png'),
            pngLeftBlack: require('../assets/img/left_black.png'),
            pngRightBlack: require('../assets/img/right_black.png'),
            pngAboutHkstp: require('../assets/img/about_hkstp.png'),
            pngAboutHkstp1: require('../assets/img/about_hkstp1.png'),
            pngTheNeighBorhood1: require('../assets/img/the_neighborhood.png?t=333'),
            pngTheNeighBorhood2: require('../assets/img/the_neighborhood_mobile.png?t=333'),
            pngGettingHere: require('../assets/img/getting_here.png'),
            pngRightBack:require('../assets/img/right_black.png'),
            mapTagetherPNG:require('../assets/img/monnery_map.jpg'), 
            imgIndex: 1,
            pngImg: '',
            pngLeftTextStyle: {
                color: 'white'
            },
            pngRightTextStyle: {
                color: 'white'
            },
            pngLeftText: '',
            pngRightText: '',
            pngLeft: '',
            pngRight: '',
            imageIndex : 0,
            pngTheNeighBorhood:null,
            clientWidth:0,
            timer:false,
            screenWidths:0,
        }
    },
    methods: {
        next_info() {
            this.imgIndex++;
            if (global.getClientWidth() <= global.mobileWidth){
                if (this.imgIndex > 2) this.imgIndex = 1
            }else{
                if (this.imgIndex > 3) this.imgIndex = 1
            }
            switch (this.imgIndex) {
                case 1:
                    this.pngImg = this.pngAboutHkstp
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = 'GETTING HERE'
                    this.pngRightText = 'THE NEIGHBORHOOD'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 2:
                    this.pngImg = this.pngTheNeighBorhood
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = 'ABOUT HKSTP'
                    this.pngRightText = 'GETTING HERE'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 3:
                    this.pngImg = this.pngGettingHere
                    this.pngLeft = this.pngLeftBlack
                    this.pngRight = this.pngRightBlack
                    this.pngLeftText = 'THE NEIGHBORHOOD'
                    this.pngRightText = 'ABOUT HKSTP'
                    this.pngLeftTextStyle = {
                        color: 'black'
                    }
                    this.pngRightTextStyle = {
                        color: 'black',
                    }
                    break
            }
        },
        jumpUrl(){
           if(this.imgIndex == 3){
              window.open("https://goo.gl/maps/oKRFTzsPKFCW7vsr8");           
           }
        },
        jumpUrls(){
            window.open("https://goo.gl/maps/oKRFTzsPKFCW7vsr8");  
        },
        up_info() {
            this.imgIndex--
            if (global.getClientWidth() <= global.mobileWidth){
                if (this.imgIndex === 0) this.imgIndex = 2
            }else{
                if (this.imgIndex === 0) this.imgIndex = 3
            }
            switch (this.imgIndex) {
                case 1:
                    this.pngImg = this.pngAboutHkstp
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = 'GETTING HERE'
                    this.pngRightText = 'THE NEIGHBORHOOD'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 2:
                    this.pngImg = this.pngTheNeighBorhood
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = 'ABOUT HKSTP'
                    this.pngRightText = 'GETTING HERE'
                    this.pngLeftTextStyle = {
                        color: 'white'
                    }
                    this.pngRightTextStyle = {
                        color: 'white'
                    }
                    break
                case 3:
                    this.pngImg = this.pngGettingHere
                    this.pngLeft = this.pngLeftWhite
                    this.pngRight = this.pngRightWhite
                    this.pngLeftText = 'THE NEIGHBORHOOD'
                    this.pngRightText = 'ABOUT HKSTP'
                    this.pngLeftTextStyle = {
                        color: 'black'
                    }
                    this.pngRightTextStyle = {
                        color: 'black'
                    }
                    break
            }
        }
    },
    mounted() {
        var that = this;
        this.imgIndex = 1
        this.pngImg = this.pngAboutHkstp
        this.pngLeft = this.pngLeftWhite
        this.pngRight = this.pngRightWhite
        this.pngLeftText = 'GETTING HERE'
        this.pngRightText = 'THE NEIGHBORHOOD'
        this.pngTheNeighBorhood = this.pngTheNeighBorhood1
        this.pngLeftTextStyle = {
            color: 'white'
        }
        this.pngRightTextStyle = {
            color: 'white'
        }
         this.$nextTick(function(){
            that.clientWidth = global.getClientWidth();
       })
        window.addEventListener("resize", function () {
            that.screenWidths = global.getClientWidth();
        });
        
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  _this.timer = true;
                  setTimeout(function(){
                      _this.screenWidths = _this.clientWidth = global.getClientWidth();
                      if(_this.screenWidths <= global.mobileWidth){
                        _this.pngTheNeighBorhood = _this.pngTheNeighBorhood2
                      }else{
                        _this.pngTheNeighBorhood = _this.pngTheNeighBorhood1
                      }
                      if(_this.imgIndex == 2){
                        _this.pngImg = _this.pngTheNeighBorhood
                      }

                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },

}
</script>

<style lang="less">

</style>
