import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueCookies from 'vue-cookies'
import i18n from '@/locales' 
// 引用ant
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,axios)
Vue.use(Antd)
Vue.use(VueCookies)
Vue.config.productionTip = false
new Vue({
  router, 
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
