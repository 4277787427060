<template>
  <div id="app">
    <a-row class="main-box">
      <a-col :span="6" class="showDiv"></a-col>
      <a-col class="content">
        <router-view />
      </a-col>
      <a-col :span="6" class="showDiv"></a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    if (!this.$cookies.isKey("session_id")) {
      this.$cookies.set("session_id", Math.floor(Math.random() * 10000000000));
    }
  },
};
</script>
<style lang="less">
@import "./assets/css/public.less";

#app {
  text-align: center;
  font-family: "Source Sans Pro";
  color: black;
  // font-style: normal;
  // font-weight: 400;
  // text-align: center;
  @media (max-width: 1024px) {
    .showDiv {
      display: none;
    }
  }

  @media (min-width: 1024.1px) {
    .showDiv {
      display: show;
    }
  }
  .mian {
    max-width: 1080px;
    margin: 0 auto;
  }
  .content {
    max-width: 100%;
    margin: 0 auto;
    .nav {
      position: relative;
      max-width: 100%;
      left: 0;
      .ant-row {
        display: flex;
        width: 100%;
      }
    }
  }
  /*
    @media (min-width:1280px) and (max-width: 1440px) {
        .mian{
            max-width: 100%;
            margin: 0 auto;
        }
    }*/
  @media (min-width: 768px) and (max-width: 1080px) {
    .mian {
      max-width: 768px;
      margin: 0 auto;
    }
  }
  // width: 100%;
}
</style>
