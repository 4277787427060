<script>
  export default
  {
  	clientWidth:1080,
  	mobileWidth:1079,
  	screenWidth:document.body.clientWidth,
    language:'en',
  	getClientWidth:function(){
  		if(document.body.clientWidth >= 1080){
  			return 1080;
  		}
  		/*
  		if(document.body.clientWidth > 1280 && document.body.clientWidth <= 1440){
  			return document.body.clientWidth;
  		}*/
  		if(document.body.clientWidth > 768 && document.body.clientWidth < 1080){
  			return 768;
  		}
  		return document.body.clientWidth;
  	},
  	getScreenWidth:function(){
  		return document.body.clientWidth;
  	},
    chatbot:{
        chatbot_id:"708713985125519360",
        api_domain:"http://innocell.uat.altech.hk/",
        sessionId(){
            Math.ceil(Math.random()*10000);
        },
        elementContent(className,content,isSpan){
            var element= document.querySelector('#_messageList');
            var node=document.createElement("div");    
                node.className=className
            var text = document.createTextNode(content);
            node.appendChild(text)
            var spanNode = null
            if(isSpan){
                spanNode = document.createElement("span");  
                spanNode.className ="messsage-reload" ;
                node.appendChild(spanNode)  
            }
            element.appendChild(node)
            return spanNode;
        }
    },
    api:{
      domain:"http://innocell.uat.altech.hk/",// "http://innocell.uat.altech.hk/",  //https://innocell.hkstp.org
      headers:{
        'Access-Control-Allow-Origin':"*",
        "Content-Type":"application/x-www-form-urlencoded; charset=UTF-8",

      }
    }
  }
</script>