import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'
Vue.use(VueI18n)
Vue.use(VueCookies)
const DEFAULT_LANG = 'en'
const LOCALE_KEY = 'lang'

const locales = {
  zh_HK: require('./zh-HK.json'),
  zh_CN: require('./zh-CN.json'),
  en: require('./en.json'),
}

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
})
export const setLangUp = lang => {
  if (lang === undefined) {
    lang = getQueryVariable('lang');
    if(lang == false){
       lang = Vue.$cookies.get('language');
    }    
    if(lang != '' && lang != null && lang != undefined){
        lang = lang.replace('-',"_");
    }
    
    if (locales[lang] === undefined) {
       lang = DEFAULT_LANG
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang)
  Vue.$cookies.set('language',lang);
  Object.keys(locales).forEach(lang => {
    document.body.classList.remove(`lang-${lang}`)
  })
  document.body.classList.add(`lang-${lang}`)
  document.body.setAttribute('lang', lang)

  Vue.config.lang = lang
  i18n.locale = lang
}
function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}

export default i18n