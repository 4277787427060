<template>
<div id="SmartLiving" class="smart-living-list">
    <div class="smart text-left" v-if="1 === imgIndex">
        <div class="smart-living">
            <a-row>
                <a-col>LIGHT</a-col>
                <a-col>
                    <a-switch :checked="statusLight" @change="change_status('light')" />
                </a-col>
                <a-col>TV</a-col>
                <a-col>
                    <a-switch :checked="statusTv" @change="change_status('tv')" />
                </a-col>
                <a-col>AIR CON</a-col>
                <a-col>
                    <a-switch :checked="statusAirCon" @change="change_status('aircon')" />
                </a-col>
            </a-row>
        </div>
        <div class="smart-background"></div>
    </div>
    <div class="mbackground-Img"><img :src="backgroundImg" alt=""></div>
    <div class="smart-footer text-left">
        <a-row v-if="1 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right">
                    <a-col :span="21" class="smart-subtitme">
                        <div class="smart-btn-pc">
                            <p style="font-family: 'THRONE VECTOR';margin-bottom:0;">IN NEED OF SOME “ME” TIME?</p>
                            <p style="font-family: 'THRONE VECTOR';">HAVE A SNEAK PEEK AT OUR ROOMS</p>
                        </div>
                        <div class="smart-btn-m">
                            <p style="margin-bottom:0;">VIEW OUR ROOMS</p>
                        </div>
                    </a-col>
                    <a-col class="smart-top-btn" :span="3">
                            <div class="smart-btn-pc">
                                <img :src="pngRight" class="click-btn" alt="" @click="next_info">
                            </div>
                            <div class="smart-btn-m">
                                <img :src="pngRightM" class="click-btn" style="width:30px" alt="" @click="next_info">
                            </div>
                    </a-col>
                </a-row>
                
                <a-row class="smart-footer-title">
                    <a-col><span >
                    SMART L<span class="smart-title-nan" style="position: relative;">I
                            <img :src="yelowImageUrlExclaimation" style="width: 30px;position: absolute;z-index: 1;top:-8px;left:-10px;"/>
                    </span>VING
                    
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content">
                            InnoCell’s Smart Living technologies are so smart you won’t even believe it’s possible — but it is.  We use cutting-edge tech to provide end-to-end solutions designed to enhance both your experience and our own operational efficiency.  Our smart technology integration is innovative, yet non-intrusive — so seamless that won’t be able to imagine living without it.  It’s almost too good to be true.
                        </div>
                        <div class="smart_seeto_more">Click to see Smart Living features...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                            <div class="PRODUCTS-002">
                                <p style="background:none;"><i>EASE OF ACCESS</i></p>
                                <p>Seamless Self Check-In Kiosk</p>
                                <p>Advanced Facial<br/>Recognition Access</p>
                                <p>Keyless Mobile Door Access</p>
                                <p>Secured Cashless Payments</p>
                            </div>
                        </a-col>
                        <a-col :span="7">
                            <div class="PRODUCTS-002">
                                <p style="background:none;"><i>PRODUCTS & SERVICES</i></p>
                                <p>Tenant-Only InnoCell Living App</p>
                                <p>Your Personal Google Butler</p>
                                <!-- <p>Multi-Language Chatbot</p> -->
                                <p>High-Speed Internet Network</p>
                                <p>Home Automation System</p>
                                <p>IAQ System</p>
                                <p>24-Hour Security</p>
                            </div>
                        </a-col>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="2 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                    <a-col class="smart-top-btn mart-button-moblie" :span="3">
                        <img :src="pngLeft" class="click-btn "  alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                        <img :src="pngRight" class="click-btn"  alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE POWERHUB
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content">
                            Your company, your way.  Got a great idea and can’t wait to get to work on it?  Fast-track your project team’s success with THE POWER HUB, an innovative private co-working and living space, featuring 8 x individual rooms connected by a private co-working space to get your creative juices flowing.
                        </div>
                        <div class="smart_seeto_more">Click to see Smart Living features...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                      
                            <div class="text-color-green">
                                <p class="text-1">8 x 183-sqft Single Bed Private Rooms</p>
                                <p class="text-6">1 x 700+ sqft Private Co-Working Unit</p>
                                <p class="text-2">1 Single Bed Per Room</p>
                                <p class="text-3">Designed for Teams</p>
                                <p class="text-4">Fully-Furnished</p>
                                <p class="text-5">WiFi-6</p>
                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">InnoCell Smart Living System</p>

                                <p class="text-7">Facial Recognition Room Access</p>
                                </div>
                        </a-col>
                        <a-col :span="7">
                             <div class="text-wile">
                                <p class="text-8">Smart TVs</p>
                                <p class="text-9" style="line-height:110%;">On-Demand Housekeeping at a reasonable price</p>
                                <p class="text-10">Private Bathrooms</p>
                                <p class="text-11" >Refrigerator & Utensils</p>
                                <p class="text-12" >Bed Linens & Towels Upon Check-in</p>
                                <p class="text-13" >Rental Includes Utilities (Ex. Air-Conditioning)</p>
                            </div>
                        </a-col>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="3 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                    <a-col class="smart-top-btn mart-button-moblie" :span="3">
                      <img :src="pngLeft" class="click-btn" alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                      <img :src="pngRight" class="click-btn" alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE SOLO
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content">
                            We all need a bit of “me” time — a place to sing in the shower and hang-out in our comfy PJs.  THE SOLO is the perfect private space for you to relax and unwind at the end of a busy day.
                        </div>
                        <div class="smart_seeto_more">Click to see Smart Living features...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                                <div class="text-color-green">
                                <p class="text-1">248-sqft</p>
                                <p class="text-2">1 Double Bed (Max. 2 People)</p>
                                <p class="text-3">Designed for Individuals</p>
                                <p class="text-4">Fully-Furnished</p>
                                <p class="text-5">WiFi-6</p>
                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">InnoCell Smart Living System</p>

                                <p class="text-7"> Facial Recognition Room Access</p>
                                </div>
                      
                        </a-col>
                        <a-col :span="7">
                            <div class="text-wile">
                                <p class="text-8">Smart TVs</p>
                                <p class="text-9">On-Demand Housekeeping at a Reasonable price</p>
                                <p class="text-10">Private Bathroom</p>
                                <p class="text-11">Refrigerator & Utensils</p>
                                <p class="text-12">Bed Linens & Towels Upon Check-in</p>
                                <p class="text-13">Rental Includes Utilities (Ex. Air-Conditioning)</p>
                            </div>
                                
                        </a-col>
                     </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="4 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                     <a-col class="smart-top-btn mart-button-moblie" :span="3">
                      <img :src="pngLeft" class="click-btn" alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                      <img :src="pngRight" class="click-btn" alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE TWIN
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span></a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content">
                            “Double-down” with your friend or co-worker in THE TWIN; share a comfortable living space with ample room for both socialising and privacy.  Because, let’s face it, having a great roommate can make your stay double the fun.
                        </div>
                        <div class="smart_seeto_more">Click to see Smart Living features...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                       
                            <div class="text-color-green">
                                <p class="text-1">248-sqft</p>
                                <p class="text-2">2 Individual Single Beds(Max. 2 People)</p>
                                <p class="text-3">Designed for Individuals</p>
                                <p class="text-4">Fully-Furnished</p>
                                <p class="text-8">In-Room Safe</p>
                                <p class="text-5">Wifi-6</p>
                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">InnoCell Smart Living System</p>

                                <p class="text-7"> Facial Recognition Room Access</p>
                                </div>
                        </a-col>
                        <a-col :span="7">
                            <div class="text-wile">
                                <!-- <p class="text-8">Smart TVs</p> -->
                                <p class="text-9">On-Demand Housekeeping at a reasonable price</p>
                                <p class="text-10">Private Bathroom</p>
                                <p class="text-11">Refrigerator & Utensils</p>
                                <p class="text-12">Bed Linens & Towels Upon Check-in</p>
                                <p class="text-13">Rental Includes Utilities  <br/>(Ex. Air-Conditioning)</p>
                            </div>
                        </a-col>
                     </a-col>
                </a-row>
            </a-col>
        </a-row>
        <a-row v-if="5 === imgIndex">
            <a-col>
                <a-row class="smart-footer-title-button text-right smart-button-img">
                    <a-col class="smart-top-btn mart-button-moblie" :span="3">
                      <img :src="pngLeft" class="click-btn" alt="" @click="prex_info">
                    </a-col>
                    <a-col class="smart-top-btn" :span="21">
                      <img :src="pngRight" class="click-btn" alt="" @click="next_info">
                    </a-col>
                </a-row>
                <a-row class="smart-footer-title">
                    <a-col><span style="position: relative;">
                    THE SUITE
                    <img :src="yelowImageUrlExclaimation" style="width: 35px;position: absolute;z-index: 1;top:-25px;right:-30px;"/>
                    </span>
                    
                    </a-col>
                </a-row>
                <a-row class="smart-footer-content " :gutter="[48,16]">
                    <a-col :span="10">
                        <div class="smart-left-content">
                            Live the “suite” life!  They say home is where the heart is — and for most people, our hearts belong to our family and friends.  Bring the whole gang with you to THE SUITE, our most spacious units, with separate living and sleeping areas for you to enjoy quality-time and gatherings with your family and friends in privacy.
                        </div>
                        <div class="smart_seeto_more">Click to see Smart Living features...</div>
                    </a-col>
                    <a-col :span="14" class="footer-content-more">
                        <a-col :span="7">
                      
                             <div class="text-color-green">
                                <p class="text-1">497-sqft</p>
                                <p class="text-2">1 Double Bed and 2 Sofa Beds</p>
                                
                                <p class="text-4">Fully-Furnished</p>
                                <p class="text-8">In-Room Safe</p>
                                <p class="text-5">WiFi-6</p>
                                </div>
                                <br/>
                                <div class="text-wile">
                                <p class="text-6">InnoCell Smart Living System</p>

                                <p class="text-7"> Facial Recognition Room Access</p>
                                </div>
                        </a-col>
                        <a-col :span="7">
                            <div class="text-wile">
                                <p class="text-8">Smart TVs</p>
                                <p class="text-9">On-Demand Housekeeping at a Reasonable Price</p>
                                <p class="text-10">Private Bathroom</p>
                                <p class="text-11">Refrigerator & Utensils</p>
                                <p class="text-12">Bed Linens & Towels Upon Check-in</p>
                                <p class="text-13">Rental Includes Utilities (Ex. Air-Conditioning)</p>
                            </div>
                        </a-col>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</div>
</template>

<script>
import jq from 'jquery'
import global from '../global'
export default {
    name: "SmartLiving",
    data() {
        return {
            pngSmart: require('../assets/img/gif/smart_living/smartliving_default_1015.png'),
            pngSmartAc: require('../assets/img/gif/smart_living/smartliving_ac_1015.png'),
            pngSmartLightoff: require('../assets/img/gif/smart_living/smartliving_lightoff_1015.png'),
            gifSmartTv: require('../assets/img/gif/smart_living/smart_tv_animation_1015.gif'),
            pngRight: require('../assets/img/right.png'),
            pngSmart1: require('../assets/img/smart1.png'),
            pngSmart2: require('../assets/img/smart2.png'),
            pngSmart3: require('../assets/img/smart3.png'),
            pngSmart4: require('../assets/img/smart4.png'),
            pngRightM: require('../assets/img/right_white2.png'),
            pngLeft:require('../assets/img/left.png'),
            yelowImageUrlExclaimation:require('../assets/img/gif/Green Exclaimation.png'),
            pngSmartMobile1: require('../assets/img/mobile/mobilesmart.gif'),
            pngSmartMobile2: require('../assets/img/mobile/PowerHubImage.png'),
            pngSmartMobile3: require('../assets/img/mobile/SoloImage.png'),
            pngSmartMobile4: require('../assets/img/mobile/SuiteImage.png'),
            pngSmartMobile5: require('../assets/img/mobile/TwinImage.png'),
            backgroundImg: '',
            imgIndex: 1,
            statusLight: false,
            statusTv: false,
            statusAirCon: false,
            clientWidth:0,
            timer:false,
            screenWidths:0
        }
    },
    methods: {
        change_status(name) {
            this.clientWidth = global.getClientWidth();
            switch (name) {
                case 'light':
                    this.backgroundImg = this.pngSmartLightoff
                    if(this.statusLight){
                        this.statusLight = false
                        this.statusTv = false
                        this.statusAirCon = false
                    }else{
                        this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                        this.statusLight = true
                    }
                    
                    break
                case 'tv':
                    this.backgroundImg = this.gifSmartTv
                    if(this.statusTv){
                        this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                        
                        this.statusTv = false
                        
                    }else{
                        this.statusLight = true
                        this.statusTv = true
                         this.statusAirCon = false
                    }
                    
                    break
                case 'aircon':
                    this.backgroundImg = this.pngSmartAc
                    if(this.statusAirCon){
                        this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                        this.statusAirCon = false
                    }else{
                         this.statusLight = true
                         this.statusAirCon = true
                         this.statusTv = false
                    }
                    
                    break
                default:
                    this.backgroundImg = this.clientWidth <= global.mobileWidth ? this.pngSmartMobile1 : this.pngSmart;
                    this.statusLight = true
                    this.statusTv = false
                    this.statusAirCon = false
                    break
            }
        },
        next_info() {
            this.imgIndex++;
            if (this.imgIndex > 5) this.imgIndex = 1
            jq(".smart_seeto_more").hide();
            this.clientWidth = global.getClientWidth();
            if(global.getScreenWidth() <= global.mobileWidth){
                jq(".footer-content-more").hide();
                jq(".smart_seeto_more").show();
            }
            switch (this.imgIndex) {
                case 1:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile1
                    }else{
                       this.backgroundImg = this.pngSmart
                    }
                    
                    break
                case 2:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile2
                    }else{
                       this.backgroundImg = this.pngSmart1
                    }
                    break
                case 3:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile3
                    }else{
                       this.backgroundImg = this.pngSmart2
                    }
                    break
                case 4:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile4
                    }else{
                       this.backgroundImg = this.pngSmart3
                    }
                    break
                case 5:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile5
                    }else{
                       this.backgroundImg = this.pngSmart4
                    }
                    break
            }
        },
        prex_info(){
            this.imgIndex--;
            if (this.imgIndex < 1) this.imgIndex = 5
            jq(".smart_seeto_more").hide();
            this.clientWidth = global.getClientWidth()
            if(global.getScreenWidth() <= global.mobileWidth){
                jq(".footer-content-more").hide();
                jq(".smart_seeto_more").show();
            }
            switch (this.imgIndex) {
                case 1:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile1
                    }else{
                       this.backgroundImg = this.pngSmart
                    }
                    
                    break
                case 2:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile2
                    }else{
                       this.backgroundImg = this.pngSmart1
                    }
                    break
                case 3:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile3
                    }else{
                       this.backgroundImg = this.pngSmart2
                    }
                    break
                case 4:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile4
                    }else{
                       this.backgroundImg = this.pngSmart3
                    }
                    break
                case 5:
                    if(global.getClientWidth() <= global.mobileWidth){
                        this.backgroundImg = this.pngSmartMobile5
                    }else{
                       this.backgroundImg = this.pngSmart4
                    }
                    break
            }
        },
        seeToMore(){

        },
    },
    mounted() {
        var that = this;
        jq("body").on("click",".smart_seeto_more",function(){
            jq(this).parents(".smart-footer-content").find(".footer-content-more").show();
            jq(this).hide();
        })
        this.change_status('reset')
        this.clientWidth = global.getClientWidth();
         window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
        });
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  this.timer = true;
                  setTimeout(function(){
                        _this.clientWidth = _this.screenWidths = global.getClientWidth()
                        
                       if(global.getScreenWidth() <= global.mobileWidth){
                            _this.change_status('reset')
                            jq(".footer-content-more").hide();
                            jq(".smart_seeto_more").show();
                            switch (_this.imgIndex) {
                                case 1:
                                    _this.backgroundImg = _this.pngSmartMobile1
                                    break
                                case 2:
                                    _this.backgroundImg = _this.pngSmartMobile2
                                    break
                                case 3:
                                    _this.backgroundImg = _this.pngSmartMobile3
                                    break
                                case 4:
                                    _this.backgroundImg = _this.pngSmartMobile4
                                    break
                                case 5:
                                    _this.backgroundImg = _this.pngSmartMobile5
                                    break
                            }
                        }else{
                          _this.change_status('reset')
                          jq(".footer-content-more").show();
                          jq(".smart_seeto_more").hide();
                          switch (_this.imgIndex) {
                                case 1:
                                    _this.backgroundImg = _this.pngSmart
                                    break
                                case 2:
                                    _this.backgroundImg = _this.pngSmart1
                                    break
                                case 3:
                                    _this.backgroundImg = _this.pngSmart2
                                    break
                                case 4:
                                    _this.backgroundImg = _this.pngSmart3
                                    break
                                case 5:
                                    _this.backgroundImg = _this.pngSmart4
                                    break
                            }
                        }
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },
}
</script>

<style lang="less">

</style>
